import CodeViewer from "Components/codeViewer";
import VideoPlayer from "Components/videoPlayer";

import * as urls from "Utils/urls";

import { js } from "./codes";

const codes = [
  {
    title: "Node-JS",
    lang: "javascript",
    code: js,
  },
];

const InsertCache = () => {
 
  return (
    <div className="space-y-3 flex-1">
      <p className="text-2xl font-bold">Insert Data</p>
      <div className="space-x-6 space-y-2">
        <p className="text-xl font-medium">Prerequisites</p>
        <ul className="list-disc">
          <li>
            Refer{" "}
            <a
              href={urls.PROJECT_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about creating a <b>project</b>.
          </li>
          <li>
            Refer{" "}
            <a
              href={urls.FUNCTION_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about creating a <b>function</b>.
          </li>
          <li>
            Refer{" "}
            <a
              href={urls.CACHE_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about creating a <b>cache segment</b>.
          </li>
          <li>
            Refer{" "}
            <a
              href={urls.CLI_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about <b>catalyst CLI</b>.
          </li>
        </ul>
      </div>
      <div className="space-x-2 space-y-2">
        <p className="text-xl font-medium">Overview</p>
        <p>
          You can insert a cache element using the <b>put()</b> method. This
          enables you to insert a <b>key-value</b> pair in an existing cache
          segment in your catalyst project. The key name and key value are of
          the String type and are passed as arguments to the method. You can
          also pass the expiry time for the cache element optionally. If you do
          not pass that value, the expiry time will be set to 48 hours by
          default. The promise returned here will be resolved to a JSON object.
        </p>
      </div>
      <div className="space-x-2 space-y-2">
        <p className="text-xl font-medium">Code</p>
        <CodeViewer codes={codes} />
      </div>
      <div className="space-x-2 space-y-2">
        <p className="text-xl font-medium">Result</p>
        <VideoPlayer url="/server/video_loader/static/Cache/Insert.webm" />
      </div>
    </div>
  );
};

export default InsertCache;
