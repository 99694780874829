import axios from "axios";
import OnDemandComponent from "Components/OnDemandComponent";
import ServerError from "Components/ServerError";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Dish from "./Dish";

const INTERVAL_OFFSET = 100;

const Dishes = () => {
  const controller = useRef(null);
  const { category_id } = useParams();
  const [fetchState, setFetchState] = useState("loading");
  const [dishes, setDishes] = useState([]);

  useEffect(() => {
    setFetchState("loading");
    setDishes([]);
    if (controller.current) {
      controller.current.abort();
    }
    controller.current = new AbortController();
    axios
      .get(`/server/service/dishes/${category_id}`, {
        signal: controller.current.signal,
      })
      .then((response) => {
        const {
          data: { dishes },
        } = response.data;
        setDishes(dishes);
        setFetchState("fetched");
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.log(err);
          setFetchState("error");
        }
      });
    return () => {
      if (controller.current) {
        controller.current.abort();
      }
    };
  }, [category_id]);

  return (
    <div
      style={{ height: "calc(100% - 3rem)" }}
      className="overflow-auto custom-scrollbar"
    >
      <OnDemandComponent show={fetchState === "loading"}>
        <div className="h-full mx-20">
          <div className="flex h-full items-center justify-center">
            <div className="rounded-full h-8 w-8 border-4 border-primary border-t-transparent animate-spin"></div>
          </div>
        </div>
      </OnDemandComponent>
      <OnDemandComponent show={fetchState === "error"}>
        <div className="h-full w-full select-none">
          <ServerError />
        </div>
      </OnDemandComponent>
      <OnDemandComponent show={fetchState === "fetched"}>
        <div className="mx-20 ">
          <div className="flex flex-wrap justify-center">
            {dishes.map((obj, index) => (
              <Dish
                key={obj.ROWID}
                {...obj}
                interval={INTERVAL_OFFSET * index}
              />
            ))}
          </div>
        </div>
      </OnDemandComponent>
    </div>
  );
};

export default Dishes;
