import { Link, Outlet } from "react-router-dom";

import { ReactComponent as Utensils } from "Icons/utensils.svg";
import DishInformation from "./DishInformation";
import TechnicalDetails from "./TechnicalDetails";
import { useCallback } from "react";
import EventEmitter from "Utils/EventEmitter";

const Layout = () => {
  const openTechnicalDetails = useCallback(() => {
    EventEmitter.emit("TechnicalDetails");
  }, []);
  return (
    <div className="w-screen min-w-[900px] h-screen relative bg-gray-50">
      <div className="h-14 fixed inset-x-0 bg-primary shadow-2xl">
        <div className="mx-5 flex items-center h-full text-white ">
          <div className="flex-1 ">
            <Link
              to="/bites"
              className="flex items-center space-x-1 py-1 px-2 rounded max-w-max"
            >
              <div className="p-1 rounded-full border border-white">
                <Utensils className="w-6 h-6" />
              </div>
              <p className="text-2xl font-bold">Bites</p>
            </Link>
          </div>
          <button
            className="font-semibold text-lg rounded-md py-0.5 px-2 "
            onClick={openTechnicalDetails}
          >
            Technical Details
          </button>
          <Link
            to="/docs/cache"
            className="font-semibold text-lg rounded-md py-0.5 px-2 "
          >
            Learn
          </Link>
        </div>
      </div>
      {/* dummy header */}
      <div className="h-14"></div>
      <Outlet />
      <DishInformation />
      <TechnicalDetails />
    </div>
  );
};

export default Layout;
