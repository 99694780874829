import { Dialog } from "@headlessui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import EventEmitter from "Utils/EventEmitter";

import { ReactComponent as Close } from "Icons/close.svg";
import OnDemandComponent from "Components/OnDemandComponent";
import ServerError from "Components/ServerError";
import axios from "axios";

const INTERNAL_OFFSET = 500;

export default function DishInformation() {
  const [isOpen, setIsOpen] = useState(false);
  const [fetchState, setFetchState] = useState("loading");

  const [dish, setDish] = useState({
    name: "",
    area: "",
    image: "",
    category: "",
    instructions: "",
    ingredients: [],
  });

  useEffect(() => {
    const openModal = (data) => {
      setIsOpen(true);
      setFetchState("loading");
      axios
        .get(`/server/service/details/${data.ROWID}`)
        .then((response) => {
          const {
            data: {
              data: { dish },
            },
          } = response;
          const { image_id, ...others } = dish;
          axios
            .get(`/server/service/image/${image_id}`, {
              responseType: "blob",
            })
            .then((response) => {
              const image = URL.createObjectURL(response.data);
              setDish({
                image,
                ...others,
              });
              setFetchState("fetched");
            })
            .catch((err) => {
              console.log(err);
              setFetchState("error");
            });
        })
        .catch((err) => {
          console.log(err);
          setFetchState("error");
        });
    };
    const listener = EventEmitter.addListener("DishInformation", openModal);
    return () => {
      listener.remove();
    };
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <Dialog
      as="div"
      open={isOpen}
      className="fixed inset-0 bg-black bg-opacity-50"
      onClose={closeModal}
    >
      <div className="min-h-screen flex justify-end">
        <div className="w-full max-w-xl flex flex-col bg-white">
          <Dialog.Title
            as="div"
            className="flex items-center shrink-0 px-6 py-2"
          >
            <p className="text-lg font-bold flex-1 break-words">
              How to make ?
            </p>
            <button
              className="shrink-0 p-1 rounded-lg hover:bg-gray-200"
              onClick={closeModal}
            >
              <Close className="h-5 w-5" />
            </button>
          </Dialog.Title>
          <div
            className="overflow-auto"
            style={{ height: "calc(100vh - 3rem)" }}
          >
            <OnDemandComponent show={fetchState === "loading"}>
              <div className="h-full flex items-center justify-center">
                <div className="w-8 h-8 border-4 border-primary border-t-transparent rounded-full animate-spin"></div>
              </div>
            </OnDemandComponent>
            <OnDemandComponent show={fetchState === "error"}>
              <div className="h-full flex items-center justify-center">
                <ServerError />
              </div>
            </OnDemandComponent>
            <OnDemandComponent show={fetchState === "fetched"}>
              <div className="h-full space-y-2 px-6 py-2">
                <div className="h-72 rounded-lg overflow-hidden ">
                  <img
                    src={dish.image}
                    alt={dish.name}
                    className="h-full w-full"
                  />
                </div>
                <div className="flex items-center justify-start space-x-1 w-full">
                  <OnDemandComponent
                    show={dish.category.toLowerCase() !== "vegetarian"}
                  >
                    <div className="w-5 h-5 rounded bg-red-100 border border-red-500 flex items-center justify-center">
                      <div className="w-3 h-3 rounded-full bg-red-500"></div>
                    </div>
                  </OnDemandComponent>
                  <OnDemandComponent
                    show={dish.category.toLowerCase() === "vegetarian"}
                  >
                    <div className="w-5 h-5 rounded bg-grren-100 border border-green-500 flex items-center justify-center">
                      <div className="w-3 h-3 rounded-full bg-green-500"></div>
                    </div>
                  </OnDemandComponent>
                  <p className="text-sm font-bold">{dish.area}</p>
                </div>
                <p className="font-bold break-words">{dish.name}</p>
                <p className="break-words text-gray-600">{dish.instructions}</p>
                {dish.ingredients.map((item, index) => (
                  <Ingredient
                    {...item}
                    key={item.id}
                    interval={INTERNAL_OFFSET * index}
                  />
                ))}
              </div>
            </OnDemandComponent>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

const Ingredient = (props) => {
  const { name, image_id, measure, interval } = props;

  const controller = useRef(null);
  const [image, setImage] = useState("");
  const [fetchState, setFetchState] = useState("loading");

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!image_id) {
        setImage("");
        setFetchState("fetched");
      } else {
        controller.current = new AbortController();
        axios
          .get(`/server/service/image/${image_id}`, {
            responseType: "blob",
            signal: controller.current.signal,
          })
          .then((response) => {
            const { data } = response;
            setImage(URL.createObjectURL(data));
            setFetchState("fetched");
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              console.log(err);
              setFetchState("error");
            }
          });
      }
    }, interval);
    return () => {
      if (controller.current) {
        controller.current.abort();
      }
      clearTimeout(timeout);
    };
  }, [image_id, interval]);

  return (
    <div className="flex items-center space-x-1">
      <div className="h-14 w-14 rounded-full border border-gray-200 overflow-hidden">
        <OnDemandComponent show={fetchState === "loading"}>
          <div className="h-full flex items-center justify-center">
            <div className="h-5 w-5 rounded-full border-2 border-primary border-t-transparent animate-spin "></div>
          </div>
        </OnDemandComponent>
        <OnDemandComponent show={fetchState === "error"}>
          <div className="h-full flex items-center justify-center">
            <p className="text-xs text-center px-2">Error</p>
          </div>
        </OnDemandComponent>
        <OnDemandComponent show={fetchState === "fetched"}>
          <OnDemandComponent show={!Boolean(image_id)}>
            <div className="h-full flex items-center justify-center">
              <p className="text-xs text-center break-words px-2">Error</p>
            </div>
          </OnDemandComponent>
          <OnDemandComponent show={Boolean(image_id)}>
            <img src={image} alt="preview" className="h-full w-full" />
          </OnDemandComponent>
        </OnDemandComponent>
      </div>
      <p className="capitalize">{name}</p>
      <p>-</p>
      <p>{measure}</p>
    </div>
  );
};
