export const js = `const express = require("express");
const catalystSDK = require("zcatalyst-sdk-node");

const FOLDER_ID = "Your Folder ID";

const app = express();


app.delete("/delete/:file_id", async (req, res) => {
  try {
    const { file_id } = req.params;
    const catalyst = catalystSDK.initialize(req);
    const folder = catalyst.filestore().folder(FOLDER_ID);

    const data = await folder.deleteFile(file_id);

    res.status(200).send({
      data: data ? "Deleted successfully" : "Deletion failed",
    });
  } catch (err) {
    console.log(err);
    res.status(500).send(err);
  }
});

module.exports = app;`;
