import * as urls from "Utils/urls";

import CodeViewer from "Components/codeViewer";
import VideoPlayer from "Components/videoPlayer";

import { js } from "./codes";

const codes = [
  {
    title: "Node-JS",
    lang: "javascript",
    code: js,
  },
];

const UploadFile = () => {
  return (
    <div className="space-y-3 flex-1">
      <p className="text-2xl font-bold">Upload File</p>
      <div className="space-x-6 space-y-2">
        <p className="text-xl font-medium">Prerequisites</p>
        <ul className="list-disc">
          <li>
            Refer{" "}
            <a
              href={urls.PROJECT_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about creating a <b>project</b>.
          </li>
          <li>
            Refer{" "}
            <a
              href={urls.FUNCTION_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about creating a <b>function</b>.
          </li>
          <li>
            Refer{" "}
            <a
              href={urls.FILESTORE_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about creating a <b>folder</b>.
          </li>
          <li>
            Refer{" "}
            <a
              href={urls.CLI_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about <b>catalyst CLI</b>.
          </li>
        </ul>
      </div>
      <div className="space-x-2 space-y-2">
        <p className="text-xl font-medium">Overview</p>
        <p>
          You can upload a file from your local system to an existing folder in
          the File Store, by referring to the folder's unique ID. You can upload
          an image, text document, CSV, or any type of file you need. The
          maximum size of a file that you can upload is 100 MB. A unique File ID
          is created for the file after it is uploaded. You must initially
          create a JSON configuration object for the file to be uploaded, as
          shown below. This JSON object creates a ReadStream() for the file.
        </p>
      </div>
      <div className="space-x-2 space-y-2">
        <p className="text-xl font-medium">Code</p>
        <CodeViewer codes={codes} />
      </div>
      <div className="space-x-2 space-y-2">
        <p className="text-xl font-medium">Result</p>
        <VideoPlayer url="/server/video_loader/static/Filestore/Upload.webm" />
      </div>
    </div>
  );
};

export default UploadFile;
