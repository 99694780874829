import * as urls from "Utils/urls";

import CodeViewer from "Components/codeViewer";
import VideoPlayer from "Components/videoPlayer";

import { js } from "./codes";

const codes = [
  {
    title: "Node-JS",
    lang: "javascript",
    code: js,
  },
];

const InsertSingleRow = () => {
  return (
    <div className="space-y-3 flex-1">
      <p className="text-2xl font-bold">Insert Single Row</p>
      <div className="space-x-6 space-y-2">
        <p className="text-xl font-medium">Prerequisites</p>
        <ul className="list-disc">
          <li>
            Refer{" "}
            <a
              href={urls.PROJECT_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about creating a <b>project</b>.
          </li>
          <li>
            Refer{" "}
            <a
              href={urls.FUNCTION_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about creating a <b>function</b>.
          </li>
          <li>
            Refer{" "}
            <a
              href={urls.DATASTORE_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about creating a <b>table</b>.
          </li>
          <li>
            Refer{" "}
            <a
              href={urls.CLI_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about <b>catalyst CLI</b>.
          </li>
        </ul>
      </div>
      <div className="space-x-2 space-y-2">
        <p className="text-xl font-medium">Overview</p>
       <p>
          Once you have created a table you can insert a record to the table by
          using <b>insertRow</b>. You must create a JSON object containing the
          row details in a {"{"}column name : column value{"}"} format, and pass
          it as an argument to the <b>insertRow()</b> method as shown below.
          This inserts the row in the table that you refer by its name or unique
          Table ID. A unique
          <b> ROWID</b> value for the row is automatically generated once a row
          is inserted. The promise returned here will be resolved to a JSON row
          object.
        </p>
      </div>

      <div className="space-x-2 space-y-2">
        <p className="text-xl font-medium">Code</p>
        <CodeViewer codes={codes} />
      </div>
      <div className="space-x-2 space-y-2">
        <p className="text-xl font-medium">Result</p>
        <VideoPlayer  url="/server/video_loader/static/Datastore/InsertRow.webm" />
      </div>
    </div>
  );
};

export default InsertSingleRow;
