export const js = `const express = require("express");
const catalystSDK = require("zcatalyst-sdk-node");

const app = express();
app.use(express.json());

const MAX_ROWS = 200;
const TABLE_NAME = "Your Table Name";

app.get("/getAll", async (req, res) => {
  try {
    const data = [];

    let hasNext = true;
    let nextToken = undefined;

    const catalyst = catalystSDK.initialize(req);
    const table = catalyst.datastore().table(TABLE_NAME);

    while (hasNext) {
      const {
        data: temp,
        more_records,
        next_token,
      } = await table.getPagedRows({
        nextToken,
        maxRows: MAX_ROWS,
      });
      hasNext = more_records;
      nextToken = next_token;
      temp.forEach((item) => {
        data.push(item);
      });
    }

    res.status(200).send({
      data,
    });
  } catch (err) {
    console.log(err);
    res.status(500).send(err);
  }
});


module.exports = app;`;
