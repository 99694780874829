import * as urls from "Utils/urls";

import CodeViewer from "Components/codeViewer";
import VideoPlayer from "Components/videoPlayer";

import { js } from "./codes";

const codes = [
  {
    title: "Node-JS",
    lang: "javascript",
    code: js,
  },
];

const InsertSingleRow = () => {
  return (
    <div className="space-y-3 flex-1">
      <p className="text-2xl font-bold">Insert Row</p>
      <div className="space-x-6 space-y-2">
        <p className="text-xl font-medium">Prerequisites</p>
        <ul className="list-disc">
          <li>
            Refer{" "}
            <a
              href={urls.PROJECT_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about creating a <b>project</b>.
          </li>
          <li>
            Refer{" "}
            <a
              href={urls.FUNCTION_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about creating a <b>function</b>.
          </li>
          <li>
            Refer{" "}
            <a
              href={urls.DATASTORE_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about creating a <b>table</b>.
          </li>
          <li>
            Refer{" "}
            <a
              href={urls.ZCQL_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about <b>ZCQL</b>.
          </li>
          <li>
            Refer{" "}
            <a
              href={urls.CLI_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about <b>catalyst CLI</b>.
          </li>
        </ul>
      </div>
      <div className="space-x-2 space-y-2">
        <p className="text-xl font-medium">Overview</p>
        <p>
          Once you have created a table you can insert a record to the table by
          using <b>ZCQL</b>. You can insert a record in a table by passing the
          values for each column of that table for that record.You can also pass
          values only for specific columns in a table by specifying the column
          names which the values need to be inserted for.
        </p>
      </div>

      <div className="space-x-2 space-y-2">
        <p className="text-xl font-medium">Code</p>
        <CodeViewer codes={codes} />
      </div>
      <div className="space-x-2 space-y-2">
        <p className="text-xl font-medium">Result</p>
        <VideoPlayer url="/server/video_loader/static/ZCQL/Insert.webm" />
      </div>
    </div>
  );
};

export default InsertSingleRow;
