export const js = `const express = require("express");
const catalystSDK = require("zcatalyst-sdk-node");

const app = express();
app.use(express.json());

const SEGMENT_ID = "Your Segment ID";

app.put("/update", async (req, res) => {
  try {
    const { key, value } = req.body;

    const catalyst = catalystSDK.initialize(req);

    const cache = catalyst.cache();
    const segment = cache.segment(SEGMENT_ID);

    const data = await segment.update(key, value);
    res.status(200).send({
      ...data,
    });
  } catch (err) {
    console.log(err);
    res.status(500).send(err);
  }
});

module.exports = app;`;
