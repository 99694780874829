import React from "react";

import SyntaxHighlighter from "react-syntax-highlighter";
import {
  dracula,
  defaultStyle,
} from "react-syntax-highlighter/dist/esm/styles/hljs";
import { generate } from "shortid";

const CodeViewer = (props) => {
  const copyTimeoutId = React.useRef("");
  const { codes } = props;

  const [tab, setTab] = React.useState(0);
  const [copied, setCopied] = React.useState(false);
  const [theme, setTheme] = React.useState(true);

  const [details, setDetails] = React.useState({
    code: "",
    lang: "",
  });

  React.useEffect(() => {
    setDetails({
      code: "",
      lang: "",
    });

    setDetails({
      code: codes[tab].code,
      lang: codes[tab].lang,
    });
  }, [codes, tab]);

  React.useEffect(() => {
    return () => {
      if (copyTimeoutId.current) {
        clearTimeout(copyTimeoutId.current);
      }
    };
  }, []);

  const changeTab = React.useCallback((event) => {
    const tab = parseInt(event.currentTarget.getAttribute("data-index"));
    setTab(tab);
  }, []);

  const copyCode = React.useCallback(() => {
    if (copyTimeoutId.current) {
      clearTimeout(copyTimeoutId.current);
    }
    setCopied(true);
    navigator.clipboard.writeText(details.code);
    copyTimeoutId.current = setTimeout(() => {
      setCopied(false);
    }, 3000);
  }, [details.code]);

  const changeTheme = React.useCallback(() => {
    setTheme((c) => !c);
  }, []);

  return (
    <div className="w-10/12">
      <div
        className={
          theme ? "bg-gray-800 text-white" : "!bg-gray-200 text-navyblue-900"
        }
      >
        <div className="bg-inherit px-4 py-3">
          <div className="flex items-center space-x-2">
            {codes.map((code, index) => (
              <div
                key={generate()}
                className={index === codes.length - 1 ? "flex-1" : ""}
              >
                <button
                  data-index={index}
                  onClick={changeTab}
                  className={
                    tab === index
                      ? "underline underline-offset-4 decoration-2"
                      : ""
                  }
                >
                  {code.title}
                </button>
              </div>
            ))}

            <button
              title={theme ? "Light" : "Dark"}
              onClick={changeTheme}
              className={`p-1 rounded-full ${
                theme ? "hover:bg-gray-700" : "hover:bg-gray-300"
              }`}
            >
              <svg
                aria-hidden="true"
                className="w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M8 256c0 136.966 111.033 248 248 248s248-111.034 248-248S392.966 8 256 8 8 119.033 8 256zm248 184V72c101.705 0 184 82.311 184 184 0 101.705-82.311 184-184 184z"
                ></path>
              </svg>
            </button>
            <button
              title="Copy"
              onClick={copyCode}
              className={`p-1 rounded-full ${
                theme ? "hover:bg-gray-700" : "hover:bg-gray-300"
              }`}
            >
              <svg
                aria-hidden="true"
                className="w-4 h-4"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div className="min-h-[500px] bg-inherit w-full">
          <SyntaxHighlighter
            language={details.lang}
            showLineNumbers
            style={theme ? dracula : defaultStyle}
            className={`!text-[14px] !p-0 !px-2 !bg-inherit !text-inherit`}
          >
            {details.code}
          </SyntaxHighlighter>
        </div>
      </div>
      <div
        className={`fixed left-[50%] bottom-10 bg-blue-50 p-2.5 rounded text-navyblue-900 flex items-center ${
          copied ? "block" : "hidden"
        }`}
      >
        <svg
          aria-hidden="true"
          className="w-5 h-5 mr-2"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
          ></path>
        </svg>
        <p>Code copied to the clipboard</p>
      </div>
    </div>
  );
};

export default CodeViewer;
