const PageNotFound = () => {
  return (
    <div className="flex justify-center items-center min-h-full">
      <div className="flex flex-col justify-center items-center">
        <p className="text-8xl font-bold">404</p>
        <p className="text-2xl font-bold">Not found</p>
        <p className="text-lg">
          The resource requested could not be found on the server.
        </p>
      </div>
    </div>
  );
};

export default PageNotFound;
