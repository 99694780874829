export const PROJECT_URL =
  "https://catalyst.zoho.com/help/catalyst-console.html";

export const FUNCTION_URL =
  "https://catalyst.zoho.com/help/functions-implementation.html#Creating";

export const CLI_URL =
  "https://catalyst.zoho.com/help/cli-command-reference.html";

export const CACHE_URL = "https://catalyst.zoho.com/help/cache.html";

export const DATASTORE_URL = "https://catalyst.zoho.com/help/data-store.html";

export const FILESTORE_URL = "https://catalyst.zoho.com/help/file-store.html";

export const ZCQL_URL = "https://catalyst.zoho.com/help/zcql.html";

export const RESOURCES_URL = "https://catalyst.zoho.com/help/";

export const CATALYST_CONSOLE_URL =
  "https://console.catalyst.zoho.com/baas/index";

export const CATALYST_URL = "https://catalyst.zoho.com/";
