export const js = `const express = require("express");
const catalystSDK = require("zcatalyst-sdk-node");

const app = express();

app.get("/getAll", async (req, res) => {
  try {
    const catalyst = catalystSDK.initialize(req);
    const zcql = catalyst.zcql();

    const query = "SELECT * FROM People";

    const response = await zcql.executeZCQLQuery(query);

    res.status(200).send({
      data: response,
    });
  } catch (err) {
    console.log(err);
    res.status(500).send(err);
  }
});

module.exports = app;`;

