export const js = `const express = require("express");
const catalystSDK = require("zcatalyst-sdk-node");

const app = express();

app.get("/pagination", async (req, res) => {
  try {
    const perPage = parseInt(req.query.perPage);
    let page = parseInt(req.query.page);

    const catalyst = catalystSDK.initialize(req);

    const zcql = catalyst.zcql();

    const countQuery = \`SELECT COUNT(People.ROWID) FROM People\`;
    const countResponse = await zcql.executeZCQLQuery(countQuery);

    const total = parseInt(countResponse[0].People.ROWID);

    const totalPages = Math.ceil(total / perPage);
    if (page > totalPages) {
      page = totalPages;
    }
    const startIndex = (page - 1) * perPage + 1;
    const dataQuery = \`SELECT * FROM People LIMIT \${startIndex},\${perPage}\`;
    const dataResponse = await zcql.executeZCQLQuery(dataQuery);
    res.status(200).send({
      data: dataResponse.map((item) => item.People),
      page,
      total,
      totalPages,
    });
  } catch (err) {
    console.log(err);
    res.status(500).send(err);
  }
});
module.exports = app;`;
