import React, { useCallback, useEffect, useRef } from "react";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";

import * as urls from "Utils/urls";

import data from "./data";

import catalyst from "Images/logo.png";

const Layout = () => {
  return (
    <div className="flex flex-col h-screen min-w-[900px] bg-gray-50">
      <div className="h-14 px-5 w-full bg-navyblue-900 text-gray-50 flex items-center space-x-2 shadow-2xl absolute">
        <div className="flex-1">
          <Link
            to="/"
            className="flex max-w-max items-center h-full space-x-1 "
          >
            <img src={catalyst} alt="Logo" className="h-8 w-8" />
            <p className="text-2xl font-medium flex-1">Learn Catalyst</p>
          </Link>
        </div>
        <a
          href={urls.RESOURCES_URL}
          target="_blank"
          className="px-2 py-1 font-medium"
          rel="noreferrer"
        >
          Resources
        </a>
        <a
          href={urls.CATALYST_CONSOLE_URL}
          target="_blank"
          className="px-2 py-1 rounded font-medium hover:bg-gray-700 hover:bg-opacity-30"
          rel="noreferrer"
        >
          Access Catalyst
        </a>
      </div>
      <div className="h-14"></div>
      <div className="flex-1 flex overflow-hidden">
        <Drawer />
        <MainContainer />
      </div>
    </div>
  );
};
const Drawer = () => {
  const [state, setState] = React.useState({
    cache: false,
    datastore: false,
    filestore: false,
    zcql: false,
  });
  const openMenu = useCallback((key, value) => {
    setState((prev) => {
      const temp = { ...prev };
      for (const item of Object.keys(temp)) {
        temp[item] = false;
      }
      temp[key] = value;
      return temp;
    });
  }, []);
  return (
    <div className="w-80 bg-navyblue-900 text-white p-6 overflow-auto  ">
      {data.map((item) => (
        <CatalystComponents
          key={item.header_id}
          {...item}
          openMenu={openMenu}
          show={state[item.header_id]}
        />
      ))}
    </div>
  );
};
const MainContainer = () => {
  const { pathname } = useLocation();
  const divRef = useRef(null);
  useEffect(() => {
    divRef.current.scrollIntoView();
  }, [pathname]);

  return (
    <div className="flex-1 flex flex-col overflow-auto p-8 space-y-4">
      <div ref={divRef}></div>
      <div className="flex-1">
        <Outlet />
      </div>
      <div className="flex items-center justify-center space-x-2 pt-3">
        <p className="text-sm">Powered by</p>
        <a
          href={urls.CATALYST_URL}
          target="_blank"
          className="text-navyblue-900 flex items-center space-x-1"
          rel="noreferrer"
        >
          <img src={catalyst} alt="Logo" className="w-4" />
          <p className="text-sm">Catalyst</p>
        </a>
      </div>
    </div>
  );
};
const CatalystComponents = (props) => {
  const { show, contents, header, header_id, openMenu } = props;
  const location = useLocation();

  const toggleShow = useCallback(() => {
    openMenu(header_id, !show);
  }, [header_id, openMenu, show]);

  useEffect(() => {
    if (location.pathname.includes(header_id)) {
      openMenu(header_id, true);
    }
  }, [header_id, location.pathname, openMenu]);
  return (
    <div className="px-3">
      <div
        className="flex items-center my-1 cursor-pointer"
        onClick={toggleShow}
      >
        <p className="text-xl flex-1">{header}</p>
        <button className="bg-transparent">
          {show ? (
            <svg
              aria-hidden="true"
              className="w-4 h-4"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
              ></path>
            </svg>
          ) : (
            <svg
              aria-hidden="true"
              className="w-4 h-4"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
              ></path>
            </svg>
          )}
        </button>
      </div>

      {show &&
        contents.map((obj) => (
          <div className="pl-3" key={obj.content_id}>
            <NavLink
              to={`/docs/${header_id}/${obj.content_id}`}
              className={({ isActive }) =>
                isActive ? "text-white" : "text-neutral-400"
              }
            >
              <div className="flex items-center">
                <div className="h-6 bg-current mr-2 w-1 py-4"></div>
                <p className="text-md">{obj.title}</p>
              </div>
            </NavLink>
          </div>
        ))}
    </div>
  );
};

export default Layout;
