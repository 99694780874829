export const js = `const express = require("express");
const catalystSDK = require("zcatalyst-sdk-node");

const app = express();
app.use(express.json());

const TABLE_NAME = "Your Table Name";

app.delete("/deleteRows", async (req, res) => {
  try {
    const { ROWIDs } = req.body;

    const catalyst = catalystSDK.initialize(req);
    const table = catalyst.datastore().table(TABLE_NAME);

    const response = await table.deleteRows(ROWIDs);

    res.status(200).send({
      data: response ? "Deleted successfully" : "Deletion failed",
    });
  } catch (err) {
    console.log(err);
    res.status(500).send(err);
  }
});

module.exports = app;
`;
