import { useEffect, useState } from "react";

import axios from "axios";
import Food from "Images/food.png";

import { Link } from "react-router-dom";
import OnDemandComponent from "Components/OnDemandComponent";
import ServerError from "Components/ServerError";
import Dish from "./Dish";

const INTERVAL_OFFSET = 100;

const Bites = () => {
  const [fetchState, setFetchState] = useState("loading");
  const [popularFoods, setPopularFoods] = useState([]);
  const [link, setLink] = useState("");

  useEffect(() => {
    axios
      .get("/server/service/popular_foods")
      .then((response) => {
        const {
          data: {
            data: { popular_foods },
          },
        } = response;
        setPopularFoods(popular_foods);
        axios
          .get("/server/service/categories")
          .then((response) => {
            const {
              data: {
                data: { categories },
              },
            } = response;
            const { ROWID } = categories[0];
            setLink(`foods/${ROWID}`);
            setFetchState("fetched");
          })
          .catch((err) => {
            console.log(err);
            setFetchState("error");
          });
      })
      .catch((err) => {
        console.log(err);
        setFetchState("error");
      });
  }, []);

  return (
    <div className="overflow-auto" style={{ height: "calc(100% - 3.5rem) " }}>
      <OnDemandComponent show={fetchState === "loading"}>
        <div className="h-full w-full flex flex-col justify-center items-center space-y-2 select-none">
          <div className="flex items-center justify-center space-x-2 loader">
            <div className="h-5 w-5 rounded-full bg-primary animate-bounce"></div>
            <div className="h-5 w-5 rounded-full bg-primary animate-bounce "></div>
            <div className="h-5 w-5 rounded-full bg-primary animate-bounce "></div>
          </div>
          <div className="font-bold text-gray-800 text-xl">
            <p>We're getting things ready.</p>
          </div>
        </div>
      </OnDemandComponent>
      <OnDemandComponent show={fetchState === "error"}>
        <div className="h-full w-full select-none">
          <ServerError />
        </div>
      </OnDemandComponent>
      <OnDemandComponent show={fetchState === "fetched"}>
        <div className="mx-20 flex flex-col">
          <div className="h-auto w-full flex items-center p-5">
            <div className="flex-1 flex flex-col space-y-5 justify-center">
              <p className="text-6xl capitalize font-bold">
                We help you to make food with extraordinary flavours &#128525;
              </p>
              <div className="w-96 text-gray-500">
                <p className="text-lg">
                  Enjoy a healty life by eating healthy foods that have
                  extraordinary flavours that make your life healthier for today
                  and in the future.
                </p>
              </div>
              <div className="flex justify-start space-x-5">
                <Link
                  to={link}
                  className="px-5 py-1 rounded-full bg-primary text-white font-medium shadow-xl"
                >
                  Explore Foods
                </Link>
              </div>
            </div>
            <div className="h-96 shrink-0">
              <img src={Food} className="h-full" alt="Food" />
            </div>
          </div>
          <div className="h-auto flex flex-col w-full">
            <p className="font-bold text-2xl px-5">Popular Foods</p>
            <div className="flex flex-wrap justify-start">
              {popularFoods.map((obj, index) => (
                <Dish
                  key={obj.ROWID}
                  {...obj}
                  interval={INTERVAL_OFFSET * index}
                />
              ))}
            </div>
          </div>
        </div>
      </OnDemandComponent>
    </div>
  );
};

export default Bites;
