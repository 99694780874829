import axios from "axios";

import { useCallback, useEffect, useRef, useState } from "react";

import OnDemandComponent from "Components/OnDemandComponent";

import { ReactComponent as Heart } from "Icons/heart.svg";
import { ReactComponent as Utensils } from "Icons/utensils.svg";

import EventEmitter from "Utils/EventEmitter";

import Tippy from "@tippyjs/react";

const Dish = (props) => {
  const {
    ROWID,
    name,
    instructions,
    image_id,
    category,
    interval,
    likes,
    area,
  } = props;
  const [image, setImage] = useState(null);
  const [fetchState, setFetchState] = useState("loading");
  const controller = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      controller.current = new AbortController();
      axios
        .get(`/server/service/image/${image_id}`, {
          responseType: "blob",
          signal: controller.current.signal,
        })
        .then((response) => {
          const { data } = response;
          setImage(URL.createObjectURL(data));
          setFetchState("fetched");
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            console.log(err);
            setFetchState("error");
          }
        });
    }, interval);
    return () => {
      if (controller.current) {
        controller.current.abort();
      }
      clearTimeout(timeout);
    };
  }, [image_id, interval]);

  const displayInformation = useCallback(() => {
    EventEmitter.emit("DishInformation", {
      ROWID,
    });
  }, [ROWID]);

  return (
    <div className="w-full overflow-hidden h-96 md:w-72  m-5 bg-white shadow-2xl flex flex-col rounded-2xl">
      <div className="h-48 w-full">
        <OnDemandComponent show={fetchState === "loading"}>
          <div className="flex justify-center items-center h-full">
            <div className="rounded-full w-6 h-6 border-2 border-primary border-t-transparent animate-spin"></div>
          </div>
        </OnDemandComponent>
        <OnDemandComponent show={fetchState === "error"}>
          <div className="flex justify-center items-center h-full">
            <p className="text-sm text-center font-bold px-2">
              Some error occured, unable to load the image.
            </p>
          </div>
        </OnDemandComponent>
        <OnDemandComponent show={fetchState === "fetched"}>
          <Tippy
            content={
              <div>
                <p className="p-1">
                  Built using <b>Filestore</b>
                </p>
              </div>
            }
            theme="light"
            placement="bottom"
            interactive
          >
            <img src={image} alt={name} className="w-full h-full" />
          </Tippy>
        </OnDemandComponent>
      </div>

      <Tippy
        content={
          <div>
            <p className="p-1">
              Built using <b>Datastore</b>
            </p>
          </div>
        }
        theme="light"
        placement="bottom"
        interactive
      >
        <div className="flex-1 flex flex-col space-y-2 py-1 px-3 ">
          <p className="font-bold truncate">{name}</p>
          <div className="flex items-center space-x-1 w-full">
            <OnDemandComponent show={category.toLowerCase() !== "vegetarian"}>
              <div className="w-5 h-5 rounded bg-red-100 border border-red-500 flex items-center justify-center">
                <div className="w-3 h-3 rounded-full bg-red-500"></div>
              </div>
            </OnDemandComponent>
            <OnDemandComponent show={category.toLowerCase() === "vegetarian"}>
              <div className="w-5 h-5 rounded bg-grren-100 border border-green-500 flex items-center justify-center">
                <div className="w-3 h-3 rounded-full bg-green-500"></div>
              </div>
            </OnDemandComponent>
            <p className="text-sm text-gray-700 font-bold">{area}</p>
          </div>
          <p className="line-clamp-4 text-sm text-gray-700">{instructions}</p>
        </div>
      </Tippy>
      <div className="flex items-center space-x-1 px-3 py-1">
        <Like likes={likes} ROWID={ROWID} interval={interval} />
        <button
          className="text-gray-600 p-1 rounded-full hover:bg-gray-200"
          onClick={displayInformation}
        >
          <Utensils className="h-6 w-6" />
        </button>
      </div>
    </div>
  );
};

const Like = (props) => {
  const { likes: initialLikes, ROWID } = props;
  const [likes, setLikes] = useState(0);
  const [liked, setLiked] = useState(false);

  useEffect(() => {
    setLikes(parseInt(initialLikes));
  }, [initialLikes]);

  const updateLikes = useCallback(() => {
    setLikes((c) => {
      return liked ? c - 1 : c + 1;
    });
    setLiked((c) => !c);
    axios
      .put(`/server/service/likes/${ROWID}`, {
        likes: liked ? likes - 1 : likes + 1,
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ROWID, liked, likes]);

  return (
    <div className="flex-1 flex items-center space-x-0.5 select-none">
      <button className="heart" liked={liked.toString()} onClick={updateLikes}>
        <Heart className="h-5 w-5 stroke-1 stroke-red-600" />
      </button>
      <p className="text-gray-500 text-sm">{likes}</p>
    </div>
  );
};

export default Dish;
