export const js = `const express = require("express");
const catalystSDK = require("zcatalyst-sdk-node");

const app = express();

app.put("/updateZCQL", async (req, res) => {
  try {
    const ROWID = req.query.ROWID
  
    const catalyst = catalystSDK.initialize(req);

    const zcql = catalyst.zcql();

    const query = \`UPDATE Table-Name SET Table-Name.column1 = value1 , Table-Name.column2 = value2,...  WHERE Table-Name.ROWID = '\${ROWID}'\`
    const response = await zcql.executeZCQLQuery(query)

    res.status(200).send({
      data:response
    });
  } catch (err) {
    console.log(err);
    res.status(500).send(err);
  }
});

module.exports = app;

`;
