import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import SyntaxHighlighter from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import * as urls from "Utils/urls";

import catalyst from "Images/logo.png";

const actualCode = `const express = require("express");
const catalystSDK = require("zcatalyst-sdk-node");
const app = express();

app.get("/initialize", async (req, res) => {
  try {
   
    const catalyst = catalystSDK.initialize(req);

    const cache = catalyst.cache();
    const datastore = catalyst.datastore();
    const filestore = catalyst.filestore();
    const zcql = catalyst.zcql();

    res.status(200).send({
      message:"Catalyst components has been initialized successfully."
    });
  } catch (err) {
    console.log(err);
    res.status(500).send(err);
  }
});

module.exports = app;`;

const LandingPage = () => {
  const [index, setIndex] = useState(0);
  const [code, setCode] = useState("");
  const interval = useRef(null);
  useEffect(() => {
    interval.current = setInterval(() => {
      setIndex((c) => (c === actualCode.length - 1 ? 0 : c + 1));
    }, 75);
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  useEffect(() => {
    if (!index) {
      setCode(actualCode.charAt(index));
    } else {
      setCode((prev) => prev + actualCode.charAt(index));
    }
  }, [index]);
  return (
    <div className="min-h-screen min-w-[900px] bg-gray-50">
      <div className="h-14 px-5 w-full bg-navyblue-900 text-gray-50 flex items-center space-x-2 shadow-2xl absolute">
        <div className="flex-1">
          <Link
            to="/"
            className="flex max-w-max items-center h-full space-x-1 "
          >
            <img src={catalyst} alt="Logo" className="h-8 w-8" />
            <p className="text-2xl font-medium flex-1">Learn Catalyst</p>
          </Link>
        </div>
        <a
          href={urls.RESOURCES_URL}
          target="_blank"
          className="px-2 py-1 font-medium"
          rel="noreferrer"
        >
          Resources
        </a>
        <a
          href={urls.CATALYST_CONSOLE_URL}
          target="_blank"
          className="px-2 py-1 rounded font-medium hover:bg-gray-700 hover:bg-opacity-30"
          rel="noreferrer"
        >
          Access Catalyst
        </a>
      </div>
      {/* Dummy Header */}
      <div className="h-14" />
      <main
        className="bg-gray-50 overflow-auto"
        style={{ height: "calc(100vh - 3.5rem)" }}
      >
        <div className="flex flex-col items-center justify-center space-y-5 p-6">
          <p className="text-6xl capitalize font-bold w-1/2 text-center">
            Get help with coding from our engineers
          </p>
          <p className="w-72 text-center text-lg leading-5">
            Learn how to code effeciently and create apps using <b>Catalyst.</b>
          </p>
          <div className="flex items-center space-x-4">
            <Link
              to="docs/cache/insert"
              className="bg-primary text-gray-50 px-4 py-1 font-medium rounded"
            >
              Start Learning
            </Link>
            <Tippy
              content={
                <div className="w-[16.5rem]">
                  <p className="p-1">
                    Simple Application built using{" "}
                    <a
                      href={urls.CATALYST_URL}
                      target="_blank"
                      rel="noreferrer"
                      className="underline"
                    >
                      <b>Catalyst</b>
                    </a>
                    .
                  </p>
                </div>
              }
              theme="light"
              placement="top"
              interactive
            >
              <Link
                to="/bites"
                className="bg-navyblue-900 text-gray-50 px-4 py-1 font-medium rounded"
              >
                Bites
              </Link>
            </Tippy>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="w-[50rem]">
            <div className="h-10 w-full bg-gray-200 rounded-t-md flex items-center px-2 select-none">
              <div className="flex-1 flex item-center justify-start space-x-1">
                <div className="bg-red-600 h-4 w-4 rounded-full"></div>
                <div className="bg-green-700 h-4 w-4 rounded-full"></div>
                <div className="bg-yellow-500 h-4 w-4 rounded-full"></div>
              </div>
              <div className="text-gray-800 text-xl font-medium px-1">x</div>
            </div>
            <SyntaxHighlighter
              language="javascript"
              style={dracula}
              className={`!text-[14px] !w-full !h-[35rem] !p-5 !bg-gray-900 !rounded-b-md`}
            >
              {code}
            </SyntaxHighlighter>
          </div>
        </div>
      </main>
    </div>
  );
};

export default LandingPage;
