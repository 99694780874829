import "Css/index.css";

import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import React from "react";

import Bites from "Views/Bites";
import BitesLayout from "Views/Bites/Layout";

import DocsLayout from "Views/Docs/Layout";

import InsertSingleRow from "Views/Docs/Datastore/InsertSingleRow";
import InsertMultipleRows from "Views/Docs/Datastore/InsertMultipleRows";
import UpdateSingleRow from "Views/Docs/Datastore/UpdateSingleRow";
import UpdateMultipleRows from "Views/Docs/Datastore/UpdateMultipleRows";
import DeleteSingleRow from "Views/Docs/Datastore/DeleteSingleRow";
import DeleteMultipleRows from "Views/Docs/Datastore/DeleteMultipleRows";
import RetrieveSingleRow from "Views/Docs/Datastore/RetrieveSingleRow";
import RetrieveAllRows from "Views/Docs/Datastore/RetrieveAllRows";

import Delete from "Views/Docs/ZCQL/Delete";
import Fetch from "Views/Docs/ZCQL/Fetch";
import Insert from "Views/Docs/ZCQL/Insert";
import Update from "Views/Docs/ZCQL/Update";
import Pagination from "Views/Docs/ZCQL/Pagination";

import InsertCache from "Views/Docs/Cache/Insert";
import UpdateCache from "Views/Docs/Cache/Update";
import DeleteCache from "Views/Docs/Cache/Delete";
import RetrieveCacheObj from "Views/Docs/Cache/RetrieveCacheObj";

import UploadFile from "Views/Docs/Filestore/Upload";
import DeleteFile from "Views/Docs/Filestore/Delete";
import PageNotFound from "Views/PageNotFound";
import RetrieveCacheVal from "Views/Docs/Cache/RetrieveCacheVal";
import LandingPage from "Views/Landing Page";
import Categories from "Views/Bites/Categories";
import Dishes from "Views/Bites/Dishes";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="" element={<LandingPage />} />
        <Route path="bites" element={<BitesLayout />}>
          <Route path="" element={<Bites />} />
          <Route path="foods" element={<Categories />}>
            <Route path=":category_id" element={<Dishes />} />
          </Route>
          <Route path="*" element={<Navigate to="" />} />
        </Route>

        <Route path="docs" element={<DocsLayout />}>
          <Route path="" element={<Navigate to="cache/insert" replace />} />

          <Route path="cache" element={<Navigate to="insert" replace />} />
          <Route path="cache/insert" element={<InsertCache />} />
          <Route path="cache/update" element={<UpdateCache />} />
          <Route path="cache/delete" element={<DeleteCache />} />
          <Route path="cache/retrieveobj" element={<RetrieveCacheObj />} />
          <Route path="cache/retrieveval" element={<RetrieveCacheVal />} />

          <Route
            path="datastore"
            element={<Navigate to="insertrow" replace />}
          />
          <Route path="datastore/insertrow" element={<InsertSingleRow />} />
          <Route path="datastore/insertrows" element={<InsertMultipleRows />} />
          <Route path="datastore/updaterow" element={<UpdateSingleRow />} />
          <Route path="datastore/updaterows" element={<UpdateMultipleRows />} />
          <Route path="datastore/retrieverow" element={<RetrieveSingleRow />} />
          <Route path="datastore/retrieverows" element={<RetrieveAllRows />} />
          <Route path="datastore/deleterow" element={<DeleteSingleRow />} />
          <Route path="datastore/deleterows" element={<DeleteMultipleRows />} />

          <Route path="filestore" element={<Navigate to="upload" replace />} />
          <Route path="filestore/upload" element={<UploadFile />} />
          <Route path="filestore/delete" element={<DeleteFile />} />

          <Route path="zcql" element={<Navigate to="insert" replace />} />
          <Route path="zcql/insert" element={<Insert />} />
          <Route path="zcql/update" element={<Update />} />
          <Route path="zcql/retrieve" element={<Fetch />} />
          <Route path="zcql/delete" element={<Delete />} />
          <Route path="zcql/pagination" element={<Pagination />} />

          <Route path="*" element={<Navigate to="cache/insert" replace />} />
        </Route>
        <Route
          path="*"
          element={
            <div className="h-screen">
              <PageNotFound />
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
