import { Dialog } from "@headlessui/react";
import { useCallback, useEffect, useState } from "react";
import EventEmitter from "Utils/EventEmitter";

import { ReactComponent as Close } from "Icons/close.svg";

export default function TechnicalDetails() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const openModal = (data) => {
      setIsOpen(true);
    };
    const listener = EventEmitter.addListener("TechnicalDetails", openModal);
    return () => {
      listener.remove();
    };
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (

      <Dialog
        as="div"
        open={isOpen}
        className="fixed inset-0 bg-black bg-opacity-50"
        onClose={closeModal}
      >
        <div className="min-h-screen flex justify-end">
          <div className="w-full max-w-xl flex flex-col bg-white">
            <Dialog.Title
              as="div"
              className="flex items-center shrink-0 px-6 py-2"
            >
              <p className="text-lg font-bold flex-1 break-words">
                Technical Details
              </p>
              <button
                className="shrink-0 p-1 rounded-lg hover:bg-gray-200"
                onClick={closeModal}
              >
                <Close className="h-5 w-5" />
              </button>
            </Dialog.Title>
            <div
              className="overflow-auto px-6 py-2 space-y-2"
              style={{ height: "calc(100vh - 3rem)" }}
            >
              <p className="font-bold">Serverless Concept</p>
              <p className="ml-4">
                Serverless is a cloud-native development model that allows
                developers to build and run applications without having to
                manage servers. You can build your application without worrying
                about maintenance and just focus on your core business logic
                alone.
              </p>
              <p className="font-bold">Serverless Website</p>
              <p className="ml-4">
                This is a website which is used for viewing the best dishes in
                whatever places you like along with recipe and other details.
                This is a serverless website which is built on Catalyst by Zoho,
                which is a full stack serverless platform. This website uses
                various components from Catalyst in order to complete its core
                functionality.
              </p>
              <p className="font-bold">Components</p>
              <p className="ml-4">
                What would be the key requirements of a website that provides a
                way to people who want to make some food with extrordinary
                flavour? Let us consider the following ones :
              </p>
              <div className="ml-8">
                <ol className="space-y-2 list-decimal ml-8">
                  <li>
                    The details of the dishes - A database to store the details.
                  </li>
                  <li>
                    Images of the dishes & ingredients - A file storage to keep
                    all the dishes and ingredients images.
                  </li>
                  <li>
                    A server side computation to group all the data and view it
                  </li>
                  <li>
                    A client side hosting solution for hosting the web client
                  </li>
                </ol>
              </div>
              <p className="font-bold">
                Now what will be the Catalyst Components that go along with
                these requirements?
              </p>
              <p className="font-bold">Datastore</p>
              <p className="ml-4">
                Catalyst Datastore is a relational database management system
                which you can use for all your database needs. Now in our
                serverless website, we need to store the dish details and query
                them whenever required. We can use the Catalyst Datastore along
                with ZCQL (Zoho Catalyst Querying Language) to fetch the data
                and show it on the website.
              </p>
              <p className="ml-4">
                Table Structure - We have four tables in the Catalyst Datastore
                which contains the details of the dishes, ingredients,
                categories and mapping of dish and ingredients. etc.
              </p>
              <p className="ml-4">
                Refer{" "}
                <a
                  href="https://catalyst.zoho.com/help/data-store.html"
                  target="_blank"
                  className="underline text-primary font-bold"
                  rel="noreferrer"
                >
                  here
                </a>{" "}
                to know more about <b>Datastore</b>
              </p>
              <p className="font-bold">Filestore</p>
              <p className="ml-4">
                Catalyst Filestore is a scalable and secure file storage
                management feature which can keep all your files in one place.
                In our serverless website, we need to store the dishes and
                ingredients images. For this case, we can use the Catalyst
                Filestore where we can upload, download and stream all kinds of
                images/files wherever required.
              </p>
              <p className="ml-4">
                Folder Structure - We have one folder in the Catalyst Filestore
                for storing all the dishes related images. The folder's name
                will be Images.
              </p>
              <p className="ml-4">
                Refer{" "}
                <a
                  href="https://catalyst.zoho.com/help/file-store.html"
                  target="_blank"
                  className="underline text-primary font-bold"
                  rel="noreferrer"
                >
                  here
                </a>{" "}
                to know more about <b>Filestore</b>
              </p>
              <p className="font-bold">Functions</p>
              <p className="ml-4">
                Now that we have all the components for performing the
                operations, we need a location where we can write our business
                logic and host the same through which we can make our
                application work. We can use the Catalyst functions for the
                same. You can write your business logic in either Java/Node.js.
              </p>
              <p className="ml-4">
                Refer{" "}
                <a
                  href="https://catalyst.zoho.com/help/functions.html"
                  target="_blank"
                  className="underline text-primary font-bold"
                  rel="noreferrer"
                >
                  here
                </a>{" "}
                to know more about <b>Functions</b>
              </p>

              <p className="font-bold">Web Client Hosting</p>
              <p className="ml-4">
                Catalyst Web Client Hosting is a normal web hosting service that
                can be used for hosting your web client. In order to host our
                serverless website, we can use this feature of Catalyst
                accordingly.
              </p>
              <p className="ml-4">
                Refer{" "}
                <a
                  href="https://catalyst.zoho.com/help/web-client-hosting.html"
                  target="_blank"
                  className="underline text-primary font-bold"
                  rel="noreferrer"
                >
                  here
                </a>{" "}
                to know more about <b>Web Client Hosting</b>
              </p>
            </div>
          </div>
        </div>
      </Dialog>
  );
}
