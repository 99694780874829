import CodeViewer from "Components/codeViewer";
import VideoPlayer from "Components/videoPlayer";

import { js } from "./codes";

import * as urls from "Utils/urls";

const codes = [
  {
    title: "Node-JS",
    lang: "javascript",
    code: js,
  },
];

const UpdateCache = () => {
  return (
    <div className="space-y-3 flex-1">
      <p className="text-2xl font-bold">Update Data</p>
      <div className="space-x-6 space-y-2">
        <p className="text-xl font-medium">Prerequisites</p>
        <ul className="list-disc">
          <li>
            Refer{" "}
            <a
              href={urls.PROJECT_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about creating a <b>project</b>.
          </li>
          <li>
            Refer{" "}
            <a
              href={urls.FUNCTION_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about creating a <b>function</b>.
          </li>
          <li>
            Refer{" "}
            <a
              href={urls.CACHE_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about creating a <b>cache segment</b>.
          </li>
          <li>
            Refer{" "}
            <a
              href={urls.CLI_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about <b>catalyst CLI</b>.
          </li>
        </ul>
      </div>
      <div className="space-x-2 space-y-2">
        <p className="text-xl font-medium">Overview</p>
       <p>
          You can update the <b>key-value</b> pair in a cache segment using the{" "}
          <b>update()</b> method. You must pass the key name and{" "}
          <b>key-value</b> which are of the String type as arguments. If the
          values aren't present, they will be inserted into the cache segment.
          The promise returned here will be resolved to a JSON object. You can
          also optionally pass the expiry time parameter. If you don't assign a
          value for that, the expiry time will be set to 48 hours by default.
          The promise returned here will be resolved to a JSON object.
        </p>
      </div>
      <div className="space-x-2 space-y-2">
        <p className="text-xl font-medium">Code</p>
        <CodeViewer codes={codes} />
      </div>
      <div className="space-x-2 space-y-2">
        <p className="text-xl font-medium">Result</p>
        <VideoPlayer url="/server/video_loader/static/Cache/Update.webm" />
      </div>
    </div>
  );
};

export default UpdateCache;
