import axios from "axios";
import OnDemandComponent from "Components/OnDemandComponent";
import ServerError from "Components/ServerError";
import { useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [fetchState, setFetchState] = useState("loading");

  useEffect(() => {
    axios
      .get("/server/service/categories")
      .then((response) => {
        const {
          data: {
            data: { categories },
          },
        } = response;
        setCategories(categories);
        setFetchState("fetched");
      })
      .catch((err) => {
        console.log(err);
        setFetchState("error");
      });
  }, []);

  return (
    <div
      className="overflow-auto relative"
      style={{ height: "calc(100% - 3.5rem) " }}
    >
      <OnDemandComponent show={fetchState === "loading"}>
        <div className="h-full w-full flex flex-col justify-center items-center space-y-2 select-none">
          <div className="flex items-center justify-center space-x-2 loader">
            <div className="h-5 w-5 rounded-full bg-primary animate-bounce"></div>
            <div className="h-5 w-5 rounded-full bg-primary animate-bounce "></div>
            <div className="h-5 w-5 rounded-full bg-primary animate-bounce "></div>
          </div>
          <div className="font-bold text-gray-800 text-xl">
            <p>We're getting things ready.</p>
          </div>
        </div>
      </OnDemandComponent>
      <OnDemandComponent show={fetchState === "error"}>
        <div className="h-full w-full select-none">
          <ServerError />
        </div>
      </OnDemandComponent>
      <OnDemandComponent show={fetchState === "fetched"}>
        <div className="h-12 fixed inset-x-0 flex items-center justify-center space-x-5 border-b border-gray-300 shadow-xl">
          {categories.map((item) => (
            <NavLink
              key={item.ROWID}
              to={item.ROWID}
              className={({ isActive }) =>
                `rounded-full border font-medium border-gray-300 text-sm px-3 py-0.5 ${
                  isActive
                    ? "bg-primary text-white"
                    : "hover:bg-primary hover:text-white text-gray-500"
                }`
              }
            >
              {item.name}
            </NavLink>
          ))}
        </div>
        <div className="h-12"></div>
        <Outlet />
      </OnDemandComponent>
    </div>
  );
};

export default Categories;
