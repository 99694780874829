const ServerError = () => {
  return (
    <div className="h-full flex flex-col items-center justify-center space-y-2">
      <p className="text-8xl leading-none font-bold">500</p>
      <p className="text-2xl font-medium">Internal Server error</p>
      <p>Sorry, we couldn't process your request.</p>
    </div>
  );
};

export default ServerError;
