const data = [
  {
    header: "Cache",
    header_id: "cache",
    contents: [
      {
        content_id: "insert",
        title: "Insert Data",
      },
      {
        content_id: "update",
        title: "Update Data",
      },
      {
        content_id: "retrieveval",
        title: "Retrieve Cache Value",
      },
      {
        content_id: "retrieveobj",
        title: "Retrieve Cache Object",
      },
      {
        content_id: "delete",
        title: "Delete Data",
      },
    ],
  },
  {
    header: "Datastore",
    header_id: "datastore",
    contents: [
      {
        content_id: "insertrow",
        title: "Insert Single Row",
      },
      {
        content_id: "insertrows",
        title: "Insert Multiple Rows",
      },
      {
        content_id: "updaterow",
        title: "Update Single Row",
      },
      {
        content_id: "updaterows",
        title: "Update Multiple Rows",
      },
      {
        content_id: "deleterow",
        title: "Delete Single Row",
      },
      {
        content_id: "deleterows",
        title: "Delete Multiple Rows",
      },
      {
        content_id: "retrieverow",
        title: "Retrieve Single Row",
      },
      {
        content_id: "retrieverows",
        title: "Retrieve All Rows",
      },
    ],
  },
  {
    header: "Filestore",
    header_id: "filestore",
    contents: [
      {
        content_id: "upload",
        title: "Upload File",
      },
      {
        content_id: "delete",
        title: "Delete File",
      },
    ],
  },
  {
    header: "ZCQL",
    header_id: "zcql",
    contents: [
      {
        content_id: "insert",
        title: "Insert Row",
      },
      {
        content_id: "update",
        title: "Update Rows",
      },
      {
        content_id: "retrieve",
        title: "Fetch Rows",
      },
      {
        content_id: "delete",
        title: "Delete Rows",
      },
      {
        content_id: "pagination",
        title: "Pagination",
      },
    ],
  },
];

export default data;
