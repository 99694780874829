import * as urls from "Utils/urls";

import CodeViewer from "Components/codeViewer";
import VideoPlayer from "Components/videoPlayer";

import { js } from "./codes";

const codes = [
  {
    title: "Node-JS",
    lang: "javascript",
    code: js,
  },
];

const UpdateSingleRow = () => {
  return (
    <div className="space-y-3 flex-1">
      <p className="text-2xl font-bold">Update Rows</p>
      <div className="space-x-6 space-y-2">
        <p className="text-xl font-medium">Prerequisites</p>
        <ul className="list-disc">
          <li>
            Refer{" "}
            <a
              href={urls.PROJECT_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about creating a <b>project</b>.
          </li>
          <li>
            Refer{" "}
            <a
              href={urls.FUNCTION_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about creating a <b>function</b>.
          </li>
          <li>
            Refer{" "}
            <a
              href={urls.DATASTORE_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about creating a <b>table</b>.
          </li>
          <li>
            Refer{" "}
            <a
              href={urls.ZCQL_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about <b>ZCQL</b>.
          </li>
          <li>
            Refer{" "}
            <a
              href={urls.CLI_URL}
              className="underline text-navyblue-900 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn more about <b>catalyst CLI</b>.
          </li>
        </ul>
      </div>
      <div className="space-x-2 space-y-2">
        <p className="text-xl font-medium">Overview</p>
        <p>
          Once you have created a table you can update a record of the table by
          using <b>ZCQL</b>. The <b>UPDATE</b> command enables you to update
          specific column values of existing records of a table in the Data
          Store. You can update the values of columns based on certain
          conditions. These conditions can be specified using the <b>WHERE</b>{" "}
          clause. You can update a maximum of 200 records in one <b>UPDATE</b> query;
        </p>
      </div>
      <div className="space-x-2 space-y-2">
        <p className="text-xl font-medium">Code</p>
        <CodeViewer codes={codes} />
      </div>
      <div className="space-x-2 space-y-2">
        <p className="text-xl font-medium">Result</p>
        <VideoPlayer url="/server/video_loader/static/ZCQL/Update.webm" />
      </div>
    </div>
  );
};

export default UpdateSingleRow;
