export const js = `const fs = require("fs");
const express = require("express");
const fileupload = require("express-fileupload");
const catalystSDK = require("zcatalyst-sdk-node");

const app = express();
app.use(fileupload());

const FOLDER_ID = "Your Folder ID";

app.post("/upload", async (req, res) => {
  try {
    const { file } = req.files;

    const path = \`\${__dirname}/tmp/\${file.name}\`;
    file.mv(path);

    const catalyst = catalystSDK.initialize(req);
    const folder = catalyst.filestore().folder(FOLDER_ID);

    const config = {
      code: fs.createReadStream(path),
      name: file.name,
    };
    const data = await folder.uploadFile(config);

    res.status(200).send({
      ...data,
    });
  } catch (err) {
    console.log(err);
    res.status(500).send(err);
  }
});


module.exports = app;`;

